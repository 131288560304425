@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.travel-documents {
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid $grey-medium;

	&__headline {
		color: $black;
		margin-bottom: 30px;
	}

	&__description {
		@extend %text;
	}

	.separator {
		margin: 30px auto;
		color: $black;
	}
}
