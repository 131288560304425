@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$listing-time-border-radius: 10px !default;
$listing-time-background-color: $color-primary !default;

.sdp-hotel-description {
	$this: &;

	.swiper-container {
		height: 250px;
	}

	&__special-offer {
		display: none; // only for print
	}

	&__photo {
		position: relative;
		overflow-x: hidden; // evite que la page bouge sur ios lateralement
	}

	&__badge {
		position: absolute;
		left: 10px;
		top: 10px;
		z-index: 1;

		.badge {
			border-radius: $listing-time-border-radius;
			background-color: $listing-time-background-color;
		}

		@include gridle_state(md) {
			display: none;
		}
	}

	&__body {
		margin-left: 10px;
		margin-right: 10px;
		margin-top: 8px;

		@include gridle_state(md) {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 30px 20px 0;
		}
	}

	&__name {
		@include gridle_state(md) {
			width: 435px;
			margin-right: 10px;
		}

		.product-name-with-category {
			@extend %font-bold;

			font-size: inherit;
			color: $black;

			@include gridle_state(md) {
				font-size: inherit;
			}
		}
	}

	&__location {
		font-size: 1.5rem;
		color: $grey-dark;

		@include gridle_state(md) {
			display: none;
		}
	}

	&__header {
		width: 100%;

		@include gridle_state(md) {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

		.product-rating {
			margin: 10px 0;
			padding-bottom: 12px;

			@include gridle_state(md) {
				padding: 0;
				margin: 0;
				border: none;
			}
		}

		.holidaycheck {
			display: flex;
			align-items: center;
			@include gridle_state(md) {
				display: grid;
				grid-template-columns: max-content 1fr;
				grid-template-rows: repeat(2, 1fr);

				&__recommendation {
					grid-column: 1/2;
					grid-row: 1/2;
				}

				&__rating-compact {
					grid-column: 2/3;
					grid-row: 1/2;
				}

				&__reviews {
					grid-column: 1/3;
					grid-row: 2/3;
					padding-top: 5px;
					text-align: right;
				}
			}
		}

		.sdp-hotel-description__name {
			width: fit-content;
		}

		.tripadvisor {
			@include gridle_state(md) {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				position: relative;
			}

			img {
				height: 19px;

				@include gridle_state(md) {
					height: 20px;
					margin-bottom: 4px;
					margin-right: -8px;
					margin-left: 4px;
				}
			}

			&__reviews {
				font-size: 1rem;

				@include gridle_state(md) {
					top: 34px;
					font-size: 1.4rem;
				}
			}
		}
	}

	.smartDPPpatchwork {
		@include gridle_state(md) {
			width: 100%;
			margin-top: 30px;
		}
	}

	&__popover-content {
		background: white;
		padding: 15px;
		border: 1px solid #d9d9d9;
		margin-right: 10px;
		margin-left: 10px;
		border-radius: 10px;
		position: relative;

		.icon {
			position: absolute;
			top: 10px;
			right: 10px;
			height: 10px;
			width: 10px;
		}
	}

	&__text {
		position: relative;
		font-size: 1.3rem;
		line-height: 20px;

		.line-clamp-view-more__view-more a,
		.line-clamp-view-more__reduce a {
			color: $black;
			text-decoration: none;
			font-size: 1.3rem;
			line-height: 20px;
		}

		@include gridle_state(md) {
			margin-top: 19px;
			font-size: 1.5rem;
			line-height: 23px;

			.line-clamp-view-more__view-more a,
			.line-clamp-view-more__reduce a {
				font-size: 1.5rem;
				line-height: 23px;
			}
		}
	}

	&__sections {
		margin-top: 12px;

		&-item {
			border-bottom: 1px solid $grey-medium;

			#{$this}-item__header {
				border-left: 1px solid $grey-medium;
				border-right: 1px solid $grey-medium;
			}

			&:first-child {
				#{$this}-item__header {
					border-top: 1px solid $grey-medium;
				}
			}
		}

		@include gridle_state(md) {
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 40px;
			margin-top: 19px;
		}
	}

	&-item {
		&__header {
			cursor: pointer;
			height: 53px;
			padding-left: 14px;
			padding-right: 14px;
			box-sizing: border-box;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-between;
			outline: none;

			@include gridle_state(md) {
				height: 60px;
				padding-left: 20px;
				padding-right: 20px;
			}

			.icon {
				width: 15px;
				height: 8px;
			}
		}

		&__title {
			color: $black;
			flex: 1 1 0%; // for click event to be trigger (see SmartDPHotelDescription.handleProductDetailsExpand)
			text-align: left; // force alignment to left to bypass heading
		}

		&__body {
			padding: 0 15px 17px;

			.additional-section {
				&__label {
					font-size: 1.4rem;
				}
			}

			@include gridle_state(md) {
				padding: 0 20px 20px;
				border-left: 1px solid $grey-medium;
				border-right: 1px solid $grey-medium;

				.additional-section {
					&__label {
						font-size: 1.5rem;
					}
				}
			}
		}
	}

	.sdp-hotel-description-loader {
		@include gridle_state(md) {
			padding: 30px 20px 20px;
		}
	}
}

@media print {
	.sdp-hotel-description {
		padding: 40px 20px 20px;

		&__badge {
			display: none;
		}

		&__body {
			display: flex;
			flex-direction: column;
		}

		&__special-offer {
			display: block;
			margin-bottom: 20px;
		}

		&__special-offer-details {
			padding: 20px;
		}

		.sanitary-message {
			background: transparent;

			&__title {
				display: flex;
			}
		}
	}

	.facility,
	.location,
	.sdp-hotel-description__text,
	.location__map,
	.negociated-list,
	.sdp-hotel-description__included,
	.useful-information,
	.sanitary-message,
	.included__list,
	.additional-sections__item {
		margin-top: 20px;
	}

	.additional-sections {
		margin-top: 30px;
	}

	.location__map {
		break-inside: avoid;
	}

	.included__title {
		font-weight: bold;
		font-size: 1.6rem;
		margin-bottom: 10px;
	}

	.location__map {
		height: 500px !important;
		width: 500px !important;
	}

	.additional-section__image {
		width: 600px;
	}

	.smartDPPpatchwork {
		display: none;
	}

	.smart-dp-header__back,
	.quotation,
	.footer {
		display: none;
	}

	.smartdp__summary {
		display: none;
	}

	#onetrust-banner-sdk {
		display: none;
	}

	.smart-dp-header {
		&__container {
			justify-content: center !important;
		}

		&__account-menu {
			display: none !important;
		}
	}
}
