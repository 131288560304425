@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-hotel-description-loader {
	img {
		width: 100%;
	}

	&__loader {
		margin-bottom: 10px;
	}

	&__header {
		display: none;
	}

	@include gridle_state(md) {
		&__header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 30px;

			.loader-bar {
				width: 100px;

				&:first-of-type {
					width: 300px;
				}
			}
		}
	}
}
