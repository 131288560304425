@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../FicheProduitCommon";

.additional-section {
	&__label {
		padding-bottom: 6px;

		@include gridle_state(md) {
			padding-bottom: 20px;
		}
	}

	&__image {
		padding-bottom: 10px;

		@include gridle_state(md) {
			height: 208px;
			padding-bottom: 20px;
		}
	}

	&__intro {
		@extend %fp-text-light;

		white-space: pre-wrap;
	}
}
