@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.line-clamp {
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	&--mutiline {
		white-space: initial;
		overflow: hidden;
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&__popover {
		.Popover-tip {
			fill: white;
			stroke: $grey-medium;
			stroke-width: 1px;
			margin-top: -1px;
		}
	}

	&__view-more {
		position: absolute;
		bottom: 0;
		right: 0;
		background: white;

		a {
			@extend %link;
			font-size: 1.1rem;
			text-decoration: underline;
			margin-left: 5px;
		}
	}
}
