@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$actionbar-height: 60px;
$header-height-desktop: 71px !default;
$header-height-mobile: 63px !default;

.smartdp {
	$this: &;

	#{$this}__subheader {
		@include gridle_state(md) {
			display: none;
		}
	}

	&__summary,
	&__summary-loader {
		padding: 10px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		background: white;
		z-index: 2;
	}

	&__summary-loader {
		padding: 10px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
		background: white;
		z-index: 1;
		position: relative;

		@include gridle_state(md) {
			display: none;
		}
	}

	&__breadcrumb {
		display: none;
	}

	&--sticky {
		.smart-dp-header {
			position: sticky;
			top: 0;
			right: 0;
			left: 0;

			@include gridle_state(md) {
				z-index: 3;
			}
		}

		#{$this}__subheader {
			position: sticky;
			top: $header-height-mobile;
			right: 0;
			left: 0;
			z-index: 2;
		}

		.smartdp__summary {
			height: 75px;
			box-sizing: border-box;
		}

		@include gridle_state(md) {
			.sdp-listing__actionbar {
				position: sticky;
				top: $header-height-desktop;
				right: 0;
				left: 0;
				z-index: 3; // 3 to be above the marketing banner
				margin-bottom: 0;
			}

			.sdp-listing__special-offer {
				display: block;
				position: sticky;
				top: calc(#{$header-height-desktop} + #{$actionbar-height});
				right: 0;
				left: 0;
				z-index: 2;
			}
		}
	}

	.confirmation {
		&__main {
			margin: 0;
		}

		&__aside {
			display: none;
		}
	}

	@include gridle_state(md) {
		&__breadcrumb {
			padding: 15px 0 28px;
			background: white;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) inset;
		}
	}

	&--search {
		.smart-dp-header {
			box-sizing: border-box;
			@include gridle_state(md) {
				box-sizing: unset;
			}

			position: absolute;
			width: 100%;
		}
	}
}
