@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$breadcrumb-color-active: $active-color !default;
$breadcrumb-color-active-dark: $active-color-dark !default;

.breadcrumbs {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;
	height: 100%;
	padding: 0 14px;

	&-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		letter-spacing: 0.1rem;

		.relative-link {
			letter-spacing: 0.1rem;
			color: $black;
		}

		&__position {
			width: 20px;
			height: 20px;
			border: solid 1px #464650;
			border-radius: 50%;
			padding-left: 1px;
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1rem;

			@include gridle_state(md) {
				font-size: 1.2rem;
			}
		}

		&__label {
			position: absolute;
			top: 110%;
			font-size: 1rem;

			@include gridle_state(md) {
				font-size: 1.2rem;
			}
		}

		&__spacer {
			content: "";
			display: inline-block;
			width: 45px;
			height: 0;
			border: solid 1px transparent;
			border-bottom-color: $grey-dark;
			margin-left: 10px;
			margin-right: 10px;

			&:last-of-type {
				display: none;
			}

			@include gridle_state(sm) {
				width: 100px;
			}
		}

		&--active {
			.breadcrumbs-item {
				&__position {
					background-color: $breadcrumb-color-active;
					border-color: $breadcrumb-color-active;
					color: white;
				}

				&__label {
					@extend %text-bold;

					color: $breadcrumb-color-active;
					font-size: 1rem;

					@include gridle_state(md) {
						font-size: 1.2rem;
					}

					cursor: default;
					pointer-events: none;
				}
			}

			.relative-link {
				cursor: default;
				pointer-events: none;
			}
		}

		&--disabled {
			color: $grey-dark;
			border-color: $grey-dark;

			.relative-link {
				cursor: default;
				pointer-events: none;
			}
		}

		&--passed {
			.breadcrumbs-item {
				cursor: pointer;

				&__position {
					border-color: $breadcrumb-color-active;
					color: $breadcrumb-color-active;
				}

				&__label {
					color: $breadcrumb-color-active;
				}
			}

			&:hover {
				.breadcrumbs-item__position {
					color: $breadcrumb-color-active-dark;
					border-color: $breadcrumb-color-active-dark;
				}

				.breadcrumbs-item__label {
					color: $breadcrumb-color-active-dark;
				}
			}

			.relative-link {
				cursor: pointer;
			}

			+ .breadcrumbs-item__spacer {
				border-bottom-color: $breadcrumb-color-active;
			}
		}
	}
}
