@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.useful-information {
	padding: 32px 16px;
	padding-bottom: 30px;

	@include gridle_state(md) {
		display: grid;
		display: -ms-grid;
		-ms-grid-columns: 470px 44px 470px;
		grid-template-columns: 470px 470px;
		grid-column-gap: 44px;
		padding: 0;
	}

	.fiche-produit-item {
		&__header {
			height: 88px;
		}

		&__body {
			padding: 0;
		}

		&__title {
			width: 100%;
			text-align: center;
		}
	}

	&__headline {
		display: none;

		@include gridle_state(md) {
			display: block;
			padding-bottom: 30px;
			margin-bottom: 30px;
			grid-column: 1 / span 2;
			color: $black;
			text-align: center;
			border-bottom: 1px solid $grey-medium;
		}
	}

	&__item {
		@include gridle_state(md) {
			grid-column: 1;

			&:nth-child(2n) {
				grid-column: 2;
			}

			&:nth-child(2n + 1) {
				grid-column: 1;
			}

			&:first-of-type {
				grid-column: 1 / span 2;
				padding: 20px 0;
				@include gridle_state(md) {
					padding: 0;
				}
			}
		}
	}

	.good-to-know {
		padding-bottom: 32px;
		margin-bottom: 32px;
		border-bottom: 1px solid $grey-medium;
	}
}
