@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.transport {
	&__headline {
		color: $black;
		margin-bottom: 30px;
	}

	&__description {
		margin-bottom: 14px;
	}
}
