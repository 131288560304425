@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.special-offer-banner {
	@extend %font-bold;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 1.2rem;
	padding: 3px 10px;
	height: 26px;
	box-sizing: border-box;

	&__picto {
		height: 13px;
		width: 13px;
		margin-right: 5px;
		background-color: currentColor;
		mask-size: contain;
		-webkit-mask-size: contain;
		mask-position: center;
		-webkit-mask-position: center;
		mask-repeat: no-repeat;
		-webkit-mask-repeat: no-repeat;
	}

	@include gridle_state(md) {
		height: 30px;
		font-size: 1.4rem;
		padding: 10px;

		&__picto {
			height: 15px;
			width: 15px;
			margin-right: 5px;
		}
	}
}
