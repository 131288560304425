@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.good-to-know {
	&__headline {
		color: $black;
		margin-bottom: 32px;
	}

	&__description {
		@extend %text;
	}

	&__perfectstay-logo {
		width: 200px;
		margin-left: 10px;
	}
}
