@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.negociated-item {
	display: flex;
	position: relative;

	$this: &;

	&__picto {
		margin-right: 10px;
		display: flex;
	}

	&__main {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	svg {
		display: flex;
		width: 20px;
		height: 17px;
	}

	img {
		width: 20px;
		height: 20px;
		padding-top: 3px;
	}

	&__label {
		padding-right: 10px;
	}

	// Mettre une règle en dur pour virer le bold des negociated (car sinon la prod va devoir virer tous les bolds)
	// sauf celui des special offer
	&:not(#{$this}--special-offer) {
		strong {
			@extend %font-regular;

			font-weight: normal;
		}
	}

	&--special-offer {
		strong {
			font-weight: normal;
		}
	}

	&__special-offer-details {
		display: none;
	}
}

@media print and (color) {
	.negociated-item__special-offer-details {
		display: block;
		font-size: 1.3rem;
	}
}
